<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          size="sm"
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.stop.prevent="showConfirmAlertToDelteProject"
        >
          <font-awesome-icon icon="trash" />
        </div>
      </template>
      <span>Supprimer le(s) projet(s)</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    dataToUse: { required: true }
  },
  methods: {
    ...mapActions(['delteProjetInobligee']),
    async showConfirmAlertToDelteProject() {
      let table = this.dataToUse;
      let htmlMessage =
        "<div  style='margin: auto;width:100%;height: 300px;overflow-x: auto;' class='ModalScrol'><div> ";
      this.tableToUse = [];
      let data = [];
      for (let i = 0; i < table.length; i++) {
        for (let k = 0; k < table[i].lots.length; k++) {
          if (
            table[i].lots[k].missing_prime_factures.length == 0 &&
            table[i].lots[k].prime_factures.length == 0 &&
            table[i].lots[k].surprime_factures.length == 0 &&
            table[i].lots[k].commission_factures.length == 0 &&
            table[i].lots[k].commission_gestion_factures.length == 0 &&
            table[i].lots[k].consolidee_energie_invest_factures.length == 0
          ) {
            let lot = { lot: table[i].lots[k].name, ids: [] };
            for (let j = 0; j < table[i].lots[k].projects.length; j++) {
              if (
                table[i].lots[k].projects[j] &&
                table[i].lots[k].projects[j].check == true
              ) {
                lot.ids.push(table[i].lots[k].projects[j].id);
                htmlMessage =
                  htmlMessage +
                  `<div style='background-color: transparent;
                        border-radius: 20px;
                        padding: 6px; margin:2px;
                        border: 1px solid;
                        color: #704ad1;'> 
                  ${table[i].lots[k].projects[j].numero_dossier}
                  </div>`;
              }
            }
            if (lot.ids.length) {
              this.tableToUse.push(lot);
              data.push({ lot: table[i].lots[k], name: table[i].name });
            }
          }
        }
      }
      htmlMessage = htmlMessage + '</div></div>';
      this.$swal
        .fire({
          background: 'rgb(245 245 252)',
          title: 'Êtes-vous sur de supprimer le(s) projet(s)  suivantes',
          type: 'warning',
          icon: 'warning',
          locale: 'fr',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          showCloseButton: true,
          showConfirmButton: true,
          showCancelButton: true,
          html: htmlMessage,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonClass: 'btn btn-success-alert',
          cancelButtonClass: 'btn btn-danger-alert',
          cancelButtonColor: '',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (result.isConfirmed) {
            const response = await this.delteProjetInobligee({
              lots: this.tableToUse,
              data: data
            });
            if (response.succes) {
              let text =
                '<div style="dispaly:flex"><div>Nombre de Projet(s) supprime avec succès : ' +
                response.deleted +
                '\n' +
                '\n' +
                '<div>Nombre de Projet(s) non supprime : ' +
                response.not_deleted +
                '</div>';
              this.$alert('', text, 'success');
            }
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.ModalScrol--scrollable ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.ModalScrol--scrollable ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.ModalScrol--scrollable ::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 7px;
  cursor: pointer !important;
}
</style>
